body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.wi-md {
  font-size: 2.8125rem !important;
}

.wi-lg {
  font-size: 5.625rem !important;
}

.loader {
  text-align: center;
  padding: 0.5rem;
  margin: 0 auto;
  display: inline-block;
  vertical-align: top;
}

.signCanvas {
  width: 100%;
  height: 100%;
}
